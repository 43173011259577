<template>
  <div :class="class_block">
    <div class="relative" :class="[error ? 'select-error' : '']">
      <label :for="id" :class="[class_label, required ? 'attention' : '']" v-if="label">{{label}}</label>
      <v-select :id="id" v-model="select_value" :multiple="multiple ? multiple : false" :reduce="reduce"
                :label="label_select" :disabled="disabled" :options="options" @option:selected="optionSelect"
                @option:deselected="optionDeselectSelect" :selectable="item => item.active" :clearable="false"
                :searchable="searchable" @search="fetchOptions" :dropdown-should-open="dropdownShouldOpen"
                :placeholder="placeholder"/>
    </div>

    <p class="absolute text-reg_red text-xs_reg" v-if="error">{{ error }}</p>
    <p :class="class_description" v-else-if="description">{{ description }}</p>

  </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

export default {
  name: "UiSelect",
  components: {vSelect},
  computed: {
    select_value: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('select_value', value, this.item)
      },
    },
  },
  props: ['id', 'class_block', 'class_label', 'class_description', 'label', 'description', 'searchable', 'options', 'is_open_dropdown', 'value', 'reduce', 'label_select', 'disabled', 'multiple', 'item', 'error', 'required', 'placeholder'],
  methods: {
    fetchOptions(search, loading) {
      this.$emit("fetchOptions", search, loading);
    },
    dropdownShouldOpen(VueSelect) {

      if (this.is_open_dropdown)
        return VueSelect.open

      return VueSelect.search.length !== 0 && VueSelect.open;
    },
    optionSelect(option) {
      this.$emit("option:selected", option, this.item);
    },
    optionDeselectSelect(option) {
      this.$emit("option:deselected", option, this.item, this.select_value);
    }
  }

}
</script>

<style>
.select-error .vs__dropdown-toggle {
  border-color: rgb(227 18 53);
  background-color: rgba(227, 18, 53, 0.03);
}
</style>