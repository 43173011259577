<template>
  <div :class="class_block">
    <div class="item flex items-center" v-for="(option, key) in options" v-bind:key="key" >
      <label class="flex items-center cursor-pointer relative text-sm_reg text-reg_black">
        <input class="opacity-0 mr-reg_8 h-reg_16 w-reg_16" type="radio" :name="code" :value="option['value']" :checked="value === option['value']" @input="$emit('input', $event.target.value)" >
        {{ option['title'] }}
        <span class="input-fake h-reg_16 w-reg_16 border border-reg_gray_50 rounded-full absolute"></span>
      </label>
    </div>
  </div>
</template>


<script lang="js">
import Vue from "vue";
export default Vue.extend({
    name: "uiRadioGroup",
    props: ['title', 'name', 'value', 'options', 'class_block', 'code'],
});
</script>