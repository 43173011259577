<template>
  <div>
    <div class="overlay bg-reg_black_90 z-90 fixed top-0 left-0 right-0 bottom-0 transition-none"></div>

    <div v-if="!loaded_blocks" class="fixed z-100 top-0 left-0 right-0 bottom-0 m-auto flex justify-center items-center">
        <div class="block-spinner_page top"></div>
    </div>

    <div class="fixed z-100 top-0 left-0 right-0 bottom-0 m-auto flex justify-center items-center" v-else>
      <div class="modal-block rounded bg-reg_white p-reg_24" :class="width > 0 ? 'w-reg_'+width : 'w-reg_750'" style="max-width: 85%; max-height: 90%; overflow: auto;">

        <div class="modal-block_header relative">
          <h2 class="modal-block_title text-lg_l_reg text-reg_black font-semibold" v-if="title">{{title}}</h2>
          <button class="btn_close absolute flex items-center justify-center right-0 top-0 rounded-full w-reg_32 h-reg_32 mt-reg_10_m mr-reg_10_m" type="button" @click="hidePopup">
              <svg-icon name="icon__close" class="icon__close w-reg_18 h-reg_18 fill-reg_black_75" />
          </button>
        </div>

        <div class="modal-block_body"  style="margin-top: 20px;min-height: 150px;">

            <div v-if="loaded_blocks">
                <div v-if="allow.access">
                    <div v-for="(block) in blocks" v-bind:key="block['code']" class="mx-reg_12_m flex flex-row flex-wrap w-full">

                      <div v-for="(field) in block['fields']" v-if="checkField(field)" v-bind:key="field['code']" :class="[block['column'] === 1 ? 'w-full' : 'w-1/'+block['column'] ]" >

                        <div class="p-reg_12">

                            <div v-if="field['type'] === 'info'">
                                <label class="relative text-sm_reg text-reg_black font-semibold">{{field['name']}}:</label>
                                <p class="mt-reg_8 w-full rounded text-sm_reg text-reg_black" v-for="(value) in field['value']" v-bind:key="value['title']">
                                  <span class="text-reg_black_75">{{value['title']}}</span>: {{value['value']}}</p>
                            </div>

                            <div v-if="field['type'] === 'info_text'">
                              <span class="text-sm_reg text-reg_black mr-reg_12"  v-for="(value) in field['value']" v-bind:key="value['title']" >{{value['title']}}: <b>{{value['value']}}</b></span>
                            </div>

                            <v-table v-else-if="field['type'] === 'table'"
                                :with_padding_x="false"
                                class_not_found = ""
                                class_block="popup_block"
                                class_table_block=""
                                :is_xlsx="false"
                                :table="field['table']"
                                :items="field['value']"
                                :loaded="true"
                                :loaded_filter="true"
                                :info="[]"
                                :pagination_data="{}"
                                @_update_item="getChangeBlocks"
                            />

                            <ui-input v-else-if="field['type'] === 'text'"
                                :label="field['name']"
                                :type="field['type']"
                                :name_custom="field['code']"
                                :name="field['code']"
                                v-model="field['value']"
                                class_block=""
                                class_label="relative text-sm_reg text-reg_black font-semibold"
                                class_input="block border border-reg_gray_75 mt-reg_8 w-full rounded text-sm_reg text-reg_black py-reg_9 px-reg_14"
                                placeholder=""
                                :required="checkRequired(field)"
                                :error="errors[field['code']]"
                                @clearError="clearError"
                            />

                            <ui-input v-else-if="field['type'] === 'number'"
                                :label="field['name']"
                                :type="field['type']"
                                :name_custom="field['code']"
                                :min="field['min']"
                                :step="field['step']"
                                :name="field['code']"
                                v-model="field['value']"
                                class_block="table"
                                class_label="relative text-sm_reg text-reg_black font-semibold"
                                class_input="block border border-reg_gray_75 mt-reg_8 w-full rounded text-sm_reg text-reg_black py-reg_9 px-reg_14"
                                placeholder=""
                                :required="checkRequired(field)"
                                :error="errors[field['code']]"
                                @clearError="clearError"
                            />

                            <ui-input v-else-if="field['type'] === 'mask'"
                                  :label="field['name']"
                                  type="text"
                                  :name_custom="field['code']"
                                  :mask="field['mask']"
                                  :name="field['code']"
                                  v-model="field['value']"
                                  class_block=""
                                  class_label="relative text-sm_reg text-reg_black font-semibold"
                                  class_input="block border border-reg_gray_75 mt-reg_8 w-full rounded text-sm_reg text-reg_black py-reg_9 px-reg_14"
                                  placeholder=""
                                  :required="checkRequired(field)"
                                  :error="errors[field['code']]"
                                  @clearError="clearError"
                            />

                            <ui-text-area v-else-if="field['type'] === 'textarea'"
                                :label="field['name']"
                                :name_custom="field['code']"
                                :name="field['code']"
                                type="text"
                                v-model="field['value']"
                                class_block="w-full"
                                class_label="relative text-sm_reg text-reg_black font-semibold"
                                class_input="block border border-reg_gray_75 mt-reg_8 w-full rounded text-sm_reg text-reg_black py-reg_9 px-reg_14 h-reg_90"
                                placeholder=""
                                :required="checkRequired(field)"
                                :error="errors[field['code']]"
                                @clearError="clearError"
                            />

                            <div class="" v-else-if="field['type'] === 'file'" >
                              <ui-input
                                  :label="field['name']"
                                  type="file"
                                  :name_custom="field['code']"
                                  :name="field['code']"
                                  v-model="field['value']"
                                  class_block=""
                                  class_label="relative text-sm_reg text-reg_black font-semibold"
                                  class_input="block border border-reg_gray_75 mt-reg_8 w-full rounded text-sm_reg text-reg_black py-reg_9 px-reg_14"
                                  placeholder=""
                                  :required="checkRequired(field)"
                                  :error="errors[field['code']]"
                                  @clearError="clearError"
                              />
                            </div>

                            <ui-checkbox v-else-if="field['type'] === 'checkbox'"
                                :name="field['code']"
                                :label="field['name']"
                                v-model="field['value']"
                                @input="(name, value) => {field['value'] = value;}"
                            />

                            <div class="" v-else-if="field['type'] === 'date'" >
                              <label v-if="field['name']" class="relative text-sm_reg text-reg_black font-semibold">{{field['name']}}</label>
                              <div class="relative py-reg_8">
                                  <date-picker
                                       :lang="ru"
                                       :currentValue="field['value']"
                                       :range = false
                                       format="DD.MM.YYYY"
                                       v-model="field['value']"
                                       type="date"
                                       valueType="DD.MM.YYYY"
                                       @change="(value) => {
                                          field['value'] = value;
                                       }"
                                  />
                              </div>
                            </div>

                            <ui-select
                                v-else-if="field['type'] === 'select'"
                                :name="field['code']"
                                v-model="field['value']"
                                :options="field['options']"
                                :id="'field_'+'_'+field['code']"
                                class_block="table md:w-full mr-reg_24 cursor-default"
                                class_label="relative text-sm_reg text-reg_black font-semibold"
                                class_description="block text-reg_gray_50 text-xs_reg mt-reg_4"
                                :label="field['name']"
                                :description="field['description']"
                                :searchable="false"
                                :is_open_dropdown="true"
                                :reduce="option => option.code"
                                :disabled="field['disabled']"
                                label_select="title"
                                @option:selected="(option) => {
                                 option = {...option};
                                     field['value'] = option['code'];
                                     clearError(field['code']);
                                }"
                                :error="errors[field['code']]"
                                :required="checkRequired(field)"
                                :placeholder="field['placeholder']"
                            />

                          <div v-else-if="field['type'] === 'radio'" class="relative">
                            <label v-if="field['name']" class="relative text-sm_reg text-reg_black font-semibold" :class="[field['required'] ? 'attention': '']">{{field['name']}}</label>
                            <ui-radio-group
                                :code="field['code']"
                                v-model="field['value']"
                                :options="field['options']"
                                :value="field['value']"
                                @input="(value) => {field['value'] = value;}"
                                class_block="checkbox-block checkbox-block_vertical flex flex-col mt-reg_12"
                            />
                            <p class="absolute text-reg_red text-xs_reg mt-reg_5" v-if="errors[field['code']]">{{ errors[field['code']] }}</p>
                          </div>

                        </div>

                      </div>

                    </div>

                    <div class="flex flex-row justify-between mt-reg_12" v-if="blocks.length > 0">
                        <div class="flex flex-row">
                            <button @click="save()" :class="[!loaded_button ? 'block-spinner_page block-spinner_20 top spinner_button' : '']" type="button" class="flex flex-row bg-reg_green hover:bg-reg_green_100 rounded font-medium text-reg_white text-sm_reg py-reg_10 px-reg_20">
                              {{buttons.save.text}}
                            </button>
                        </div>
                    </div>
                </div>
                <not-allow v-else :message="allow.text" />
            </div>

        </div>

      </div>
    </div>

  </div>

</template>

<script>
import SvgIcon from "@/components/ui/svgicon";
import PopupService from "@/common/services/popup.service";
import UiInput from "@/components/ui/form/elements/input.vue";
import UiSelect from "@/components/ui/form/elements/select.vue";
import UiCheckbox from "@/components/ui/form/elements/checkbox";
import HelperService from "@/common/services/helper.service";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import ru from 'vue2-datepicker/locale/ru';
import UiTextArea from "@/components/ui/form/elements/textarea";
import NotAllow from "@/components/ui/block/not-allow";
import VTable from "@/components/ui/table";
import UiRadioGroup from "@/components/ui/form/elements/radio-group.vue";

export default {
  name: "popupUniversal",
  components: {UiRadioGroup, NotAllow, UiTextArea, UiSelect, UiInput, SvgIcon, DatePicker, UiCheckbox, VTable},
  data: () => ({
      title : "",
      name : "",
      blocks : [],
      items : [],
      info : [],
      width : 750,
      loaded_blocks : false,
      loaded_button : true,
      allow : {
          access : true,
          text : null
      },
      buttons : {
          save : {
              text : ''
          }
      },
      errors : {},
      ru : ru,
  }),
  props: {
    type : {
      type : String,
      require : true
    },
    entity_id : {
        type : Number,
        require : true
    },
    additional : {
        type : Object,
        require : false
    }
  },
  created(){
      this.$nextTick(() => {
          this.$root.$emit("notificationHide");
          this.getBlocks();
      });
  },
  methods: {
    clearError(code){
        this.errors[code] = null;
    },
    async save(){

      this.loaded_button = false;
      this.errors = {};

      this.$root.$emit("notificationHide");

      await PopupService.saveUniversal(this.type, this.entity_id, this.getFieldsValue(), this.additional)
          .then((response) => {

            this.$root.$emit("notificationShow", {title : response.data['title'], type : "info", "text" : response.data['success_text']});
            setTimeout(() => {

              this.$root.$emit("_popup_universal_hide");
              this.$root.$emit("_update_page_custom");

            }, 50);

          }).catch((response) => {
              let errors = response.data.message.errors_format;

              if(!HelperService.isEmpty(errors['form_fields']))
                  this.errors = errors['form_fields'];
              else if(!HelperService.isEmpty(errors['all']))
                  this.$root.$emit("notificationShow", {title : response.data.message['title'], type : "error", "text" : errors['all']});
              else
                  this.$root.$emit("notificationShow", {title : response.data.message['title'], type : "error", "text" : "-"});
          })
          .finally(() => {

            setTimeout(() => {
              this.loaded_button = true;
            }, 250);

          });
    },
    async getChangeBlocks(){

      await PopupService.getChangedUniversal(this.type, this.entity_id, this.additional, this._getFieldsValue())
          .then((response) => {
              this.setData(response.data);
          })
    },
    async getBlocks(){

      this.beforeGetBlocks();
      await PopupService.getUniversal(this.type, this.entity_id, this.additional)
          .then((response) => {
            this.setData(response.data);
          }).catch((response) => {
            let errors = response.data.message.errors_format;

            if(!HelperService.isEmpty(errors['all']))
                this.allow = {access: false, text: errors['all']};

          })
          .finally(() => {
              this.afterGetBlocks();
          });
    },
    loaderFinish(){
      setTimeout(() => {
        this.loaded_blocks = true;
      }, 400);
    },
    setBlocks(blocks){
      this.blocks = blocks;
    },
    setData(data){
        this.title = data['title'];
        this.width = data['width'];
        this.setBlocks(data.blocks);
        this.allow = {
            access: true,
            text: null
        };
        this.buttons.save.text = data['button_save_text'];
    },
    beforeGetBlocks(){
        this.loaded_blocks = false;
    },
    afterGetBlocks(){
        this.loaderFinish();
    },
    hidePopup(){
        this.$root.$emit("notificationHide");
        this.$root.$emit("_popup_universal_hide")
    },
    checkField(field){

      if(HelperService.isEmpty(field['show_if']))
        return true;

      let code = field['show_if']['code'];
      let value = field['show_if']['value'];

      let success = false;
      this.blocks.forEach((block) =>  {
          block['fields'].forEach((field) =>  {

              if(field['code'] === code && field['value'] === value)
                  success = true;

          });

      });

      return success;
    },
    checkRequired(field) {

      if (HelperService.isEmpty(field['required_if']))
        return field['required'] ?? false;

      let code = field['required_if']['code'];
      let value = field['required_if']['value'];
      let required = false;

      this.blocks.forEach((block) => {
        block['fields'].forEach((field) => {

          if (field['code'] === code && field['value'] === value)
            required = true;
        });
      });

      return required;
    },
    getFieldsValue(){

      let result = [];
      this.blocks.forEach((block) =>  {
        block['fields'].forEach((field) =>  {
            result[field['code']] = field['value'];
        });
      });

      result = Object.assign({}, result);

      return result;
    }
  }
}
</script>

<style>
.vs--disabled input:disabled{
    display: none;
}
</style>